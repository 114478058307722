<template>
  <div>
    <div class="loading min-h-300" v-if="loading">
      <LoadingAnim />
    </div>
    <div class="row" v-else>
      <div class="col-md-7" :class="{ 'col-md-12': !jsonAllowed }">
        <ChatContents :messages="messages" />
      </div>
      <div class="col-md-5" v-if="jsonAllowed">
        <div class="row" style="display: flex; flex-direction: row;">
          <h4 class="mb-3" style="font-weight: 300;color: slategray;margin-left:15px">Variáveis do flow</h4>
          <span class="material-symbols-outlined"
            style="font-size:1.4em;vertical-align: middle;color:gainsboro;margin-left:5px;"
            v-tooltip.top="`Variáveis contidas no fluxo.`">
            help
          </span>
        </div>
        <JsonViewer :jsonData="jsondata" />
      </div>
    </div>
  </div>
</template>

<script>

// @ is an alias to /src
import LoadingAnim from '@/components/rebranding/animLottie/Loading.vue';
import ChatContents from '@/components/chat/ChatContents.vue';
import JsonViewer from '@/components/JsonViewer.vue';
import { filter } from 'lodash';

export default {
  name: 'FlowDetailReport',
  components: {
    LoadingAnim,
    ChatContents,
    JsonViewer,
  },
  data() {
    return {
      loading: true,
      loadingStream: true,
      messages: {},
      jsondata: {},
    };
  },
  computed: {
    jsonAllowed() {
      return ['owner', 'admin', 'developer'].includes(this.$store.state.auth.user.role);
    },
  },
  created() {
    this.fetch();
  },
  methods: {
    fetch() {
      this.loading = true;
      this.$store
        .dispatch('fetchFlowStream', this.$route.params.id)
        .then((response) => {
          this.messages = response;
        })
        .then((response) => {
          this.loading = true;
          this.$store
            .dispatch('fetchFlowStreams', { filter: { stream_id: this.$route.params.id, flowId: '', name: '' } }) // Fetch history to get "data" column, passing FlowId and Name empty to avoid undefined index error on backend.
            .then((response) => {
              this.jsondata = JSON.parse(response.data[0].data);
              this.loading = false;
            });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
