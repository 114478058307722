<template>
    <div class="json-node">
      <div v-for="(value, key) in data" :key="key">
        <div class="json-entry">
          <span
            v-if="isObject(value)"
            class="toggle-icon"
            @click="toggleExpand(key)"
          >
            {{ expandedKeys[key] ? "▼" : "▶" }}
          </span>
          <span class="json-key">"{{ key }}"</span>:
          <span v-if="isObject(value)" class="json-type"> { ... } </span>
          <span v-else :class="valueType(value)" class="json-value">
            {{ formatValue(value) }}
          </span>
        </div>
        <div v-if="expandedKeys[key]" class="json-children">
          <JsonNode v-if="isObject(value)" :data="value" :depth="depth + 1" />
        </div>
      </div>
    </div>
  </template>
<script>
export default {
  name: 'JsonNode',
  props: {
    data: {
      type: Object,
      required: true,
    },
    depth: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      expandedKeys: {},
    };
  },
  methods: {
    isObject(value) {
      return typeof value === 'object' && value !== null;
    },
    toggleExpand(key) {
      this.$set(this.expandedKeys, key, !this.expandedKeys[key]);
    },
    formatValue(value) {
      if (typeof value === 'string') return `"${value}"`;
      return value;
    },
    valueType(value) {
      return typeof value;
    },
  },
};
</script>

  <style scoped>
  .json-node {
  padding-left: 15px;
  border-left: 1px dashed rgba(0, 0, 0, 0.1);
}

.json-entry {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.toggle-icon {
  margin-right: 5px;
  cursor: pointer;
  color: #752de6;
}

.json-key {
  color: #752de6;
  font-weight: bold;
  text-wrap-mode: nowrap;
}

.json-value {
  color: #198754;
}

.json-value.string {
  color: darkslategray;
  text-wrap-mode: nowrap;
}

.json-value.number {
  color: #0d6efd;
}

.json-value.boolean {
  color: #6f42c1;
}

.json-type {
  color: #752de6;
}

.json-children {
  padding-left: 15px;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
}
  </style>
