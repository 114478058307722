<template>
    <div class="json-viewer">
      <JsonNode :data="jsonData" :depth="0" />
    </div>
  </template>
<script>
import JsonNode from './JsonNode.vue';

export default {
  components: { JsonNode },
  props: {
    jsonData: {
      type: Object,
      required: true,
    },
  },
};
</script>

  <style scoped>
.json-viewer {
  font-family: "Courier New", monospace;
  background: #f9f9f9;
  color: #333;
  padding: 15px;
  border-radius: 8px;
  font-size: 14px;
  max-width: 600px;
  overflow-x: auto;
  border: 1px solid #ddd;
}

  </style>
